import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
    overflow-x: hidden;
  }

  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    font-family: "Rubik", sans-serif;
    line-height: 1;
    font-weight: 400;
    overflow-x: hidden;
    transition: all 0.50s linear;
  }

  .buttonIcon {
    margin-right: 1rem;
  }

  .iconNoDisplay {
    display: none;
  }

  .iconDisplay {
    display: block;
  }

  .featureIcon {
    background-color:${({ theme }) => theme.primary};
    padding: 2rem;
    border-radius: 50%;
  }

  .navOpen {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transform: translateX(0);
  }

  .closeNavMenuIcon {
    color: ${({ theme }) => theme.text};
  }

  .openNavMenuIcon {
    color: ${({ theme }) => theme.headerText};
  }

  .navMenuBlackIcon {
    color: black;
  }

  .navMenuWhiteIcon {
    color: white;
  }

  .heroImageBox {
    background-image: url(../img/blob.png);
    background-size: cover;
    background-position: center;
    background-color: black;
  }

  .socialIcon {
    height: 2.4rem;
    width: 2.4rem;
  }
  
  .quoteIcon {
    fill: #ddd;
    font-size: 6rem;
    color: #ddd;
    margin-bottom: 4.8rem;
  }

  @media (max-width: 75em) {
    html {
    /* 9px / 16px  */
      font-size: 56.25%;
    }
  }

  @media (max-width: 59em) {
    /* 8px / 16px */
    html {
      font-size: 50%;
    }
  }

  .language-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.5rem;
  font-family: "Rubik", sans-serif;
}

.language-label {
  margin-right: 0.75rem;
  font-weight: 500;
  color: #374151;
  font-size: 1.4rem; /* 18px */
}

.language-select {
  padding: 0.5rem 1rem;
  padding-right: 2rem;
  border: 2px solid #D1D5DB;
  border-radius: 0.5rem;
  background-color: white;
  color: #1F2937;
  font-weight: 500;
  font-size: 1.4rem; /* 18px */
  font-family: "Rubik", sans-serif;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  min-width: 160px;
  appearance: none;
  cursor: pointer;
  transition: border-color 0.2s;
}

.language-select:hover {
  border-color: #9CA3AF;
}

.language-select:focus {
  border-color: #3B82F6;
  outline: none;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
}

.dropdown-arrow {
  position: relative;
  right: 2rem;
  pointer-events: none;
}

/* Make sure dropdown options also use the same font */
.language-select option {
  font-family: "Rubik", sans-serif;
  font-size: 1.4rem;
  padding: 8px;
}

`;
