import { useState } from 'react';
import { 
  Heading, 
  Paragraph, 
  SubHeading, 
  Caption, 
  BulletList, 
  ParagraphSlant, 
  SectionWrapper, 
  NumberedList, 
  SubHeading2 
} from "../styles/base";

const content = {
  en: {
    title: "Privacy Policy for Meal Chef AI",
    lastUpdated: "Last Updated: January 20, 2025",
    introduction: {
      title: "Introduction",
      text: "Welcome to Meal Chef AI ('we,' 'our,' or 'us'), a mobile application developed and operated by Gwinyai Nyatsoka. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application Meal Chef AI (the 'App'). Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the App."
    },
    dataController: {
      title: "Data Controller Information",
      text: "Data Controller: Gwinyai Nyatsoka\nAddress: 1312 Waxberry Way, Westgate, Harare, Zimbabwe\nEmail: gnyatsoka@gmail.com"
    },
    accountRequirement: {
      title: "Account Requirement",
      text: "To use Meal Chef AI's features, you must create an account. Account creation is only available through Google Sign-In or Apple Sign-In. Anonymous usage of the App is not permitted."
    },
    informationCollection: {
      title: "Information We Collect",
      categories: {
        title: "Categories of Personal Information",
        intro: "We collect and have collected the following categories of personal information:",
        authentication: {
          title: "Category A: Authentication Information",
          items: [
            "Email address and name (through Google or Apple Sign-In)",
            "Account identifiers",
            "Device identifiers"
          ]
        },
        userContent: {
          title: "Category B: User Content",
          items: [
            "Food photographs (when saved to your account)",
            "Saved recipes",
            "Meal preferences"
          ]
        },
        usage: {
          title: "Category C: Usage Information",
          items: [
            "App interaction data",
            "AI-generated meal suggestions",
            "Food photography analysis results"
          ]
        }
      }
    },
    howWeCollect: {
      title: "How We Collect Information",
      items: [
        "Authentication through Google or Apple Sign-In (required)",
        "Device camera access for food photography (with permission)",
        "Optional notification permissions",
        "Direct user input (preferences, saved recipes)"
      ]
    },
    legalBasis: {
      title: "Legal Basis for Processing (GDPR)",
      items: [
        "Contract: To provide our subscription-based services",
        "Consent: For processing food photographs and sending optional notifications",
        "Legitimate Interests: To improve our services and prevent abuse",
        "Legal Obligation: To comply with applicable laws and regulations"
      ]
    },
    useOfInformation: {
      title: "Use of Your Information",
      intro: "We use the information we collect to:",
      items: [
        "Provide personalized meal suggestions using AI technology",
        "Process and analyze food photographs",
        "Store your saved recipes and preferences",
        "Prevent system abuse through account identification",
        "Maintain app security and functionality",
        "Send optional trial expiration notifications (if enabled)",
        "Provide customer support",
        "Maintain appropriate language settings"
      ]
    },
    dataStorage: {
      title: "Data Storage and International Transfers",
      items: [
        "We use cloud service providers that maintain facilities in various locations globally",
        "Your data may be processed in countries outside your country of residence",
        "For transfers from the EEA, we ensure appropriate safeguards through:",
        [
          "Standard Contractual Clauses (SCCs)",
          "Adequate data protection measures",
          "Regular security assessments"
        ],
        "You have the right to request information about these safeguards"
      ]
    },
    dataRetention: {
      title: "Data Retention and Deletion",
      periods: {
        title: "We retain your information for the following periods:",
        items: [
          "Account Information: Duration of account activity",
          "Food Photographs: Until manually deleted or account deactivation",
          "Saved Recipes: Until manually deleted or account deactivation",
          "Usage Data: 12 months from collection",
          "AI Processing Data: 6 months from generation"
        ]
      },
      deactivation: {
        title: "Upon account deactivation:",
        items: [
          "All user login details are erased",
          "All photographs are deleted",
          "All saved recipes are removed",
          "All preferences are cleared",
          "All usage data is deleted"
        ]
      }
    },
    subscription: {
      title: "Subscription and Payments",
      items: [
        "Payment processing is handled entirely through Apple's App Store",
        "We do not collect or store any payment information",
        "Subscription management is handled through your Apple ID settings"
      ]
    },
    appPermissions: {
      title: "App Permissions and Features",
      items: [
        "Camera: Required for food analysis (permission required)",
        "Notifications: Optional for trial expiration reminders only",
        "Language: Available in English, French, Spanish, Portuguese (Portugal), Italian, and German",
        "Geographic Availability: Available in all territories where the Apple App Store operates"
      ]
    },
    userRights: {
      title: "Your Rights Under Data Protection Laws",
      gdpr: {
        title: "GDPR Rights (EU Users)",
        intro: "If you are a resident of the European Economic Area (EEA), you have certain data protection rights:",
        items: [
          "Right to access",
          "Right to rectification",
          "Right to erasure",
          "Right to restrict processing",
          "Right to data portability",
          "Right to object",
          "Rights related to automated decision-making"
        ]
      },
      ccpa: {
        title: "CCPA Rights (California Residents)",
        intro: "If you are a California resident, you have the right to:",
        items: [
          "Request disclosure of personal information we collect about you",
          "Request deletion of your personal information",
          "Opt-out of the sale of personal information",
          "Non-discrimination for exercising CCPA rights"
        ]
      },
      pipeda: {
        title: "PIPEDA Rights (Canadian Users)",
        intro: "Under PIPEDA, you have the right to:",
        items: [
          "Access your personal information",
          "Challenge the accuracy of your information",
          "Withdraw consent",
          "File a complaint about our privacy practices"
        ]
      }
    },
    security: {
      title: "Data Protection and Security",
      items: [
        "Access controls and authentication",
        "Regular security assessments",
        "Incident response procedures",
        "Industry-standard security safeguards"
      ]
    },
    thirdParty: {
      title: "Third-Party Services",
      items: [
        "Authentication: Google Sign-In, Apple Sign-In",
        "AI Services: We use third-party AI services for food analysis and meal suggestions",
        "Payment Processing: Handled by Apple App Store"
      ]
    },
    userControl: {
      title: "User Data Control",
      intro: "Users can:",
      items: [
        "Modify saved recipes and photographs",
        "Update meal preferences",
        "Control notification settings",
        "Request data export through support",
        "Delete account and associated data",
        "Change language preferences"
      ]
    },
    contact: {
      title: "Contact Us",
      text: "For privacy-related inquiries:\n\nGwinyai Nyatsoka\nAddress: 1312 Waxberry Way, Westgate\nHarare, Zimbabwe\nEmail: gnyatsoka@gmail.com\nResponse Time: Within 2 business days"
    },
    changes: {
      title: "Changes to Privacy Policy",
      intro: "We will notify users of any material changes through:",
      items: [
        "In-app notifications",
        "App store update notes"
      ]
    },
    cookies: {
      title: "Cookie Policy",
      text: "Our App does not use cookies directly, but third-party services may use similar technologies for authentication and functionality."
    },
    consent: {
      title: "Consent",
      text: "By creating an account and using our App, you consent to our Privacy Policy and agree to its terms. If you disagree with this policy, please do not create an account or use our App."
    }
  },
  fr: {
    title: "Politique de Confidentialité pour Meal Chef AI",
    lastUpdated: "Dernière mise à jour : 20 janvier 2025",
    introduction: {
      title: "Introduction",
      text: "Bienvenue sur Meal Chef AI (« nous », « notre » ou « nos »), une application mobile développée et exploitée par Gwinyai Nyatsoka. Cette Politique de Confidentialité explique comment nous collectons, utilisons, divulguons et protégeons vos informations lorsque vous utilisez notre application mobile Meal Chef AI (l'« Application »). Veuillez lire attentivement cette politique de confidentialité. Si vous n'acceptez pas les termes de cette politique de confidentialité, veuillez ne pas accéder à l'Application."
    },
    dataController: {
      title: "Informations du Responsable du Traitement",
      text: "Responsable du traitement : Gwinyai Nyatsoka\nAdresse : 1312 Waxberry Way, Westgate, Harare, Zimbabwe\nEmail : gnyatsoka@gmail.com"
    },
    accountRequirement: {
      title: "Obligation de Compte",
      text: "Pour utiliser les fonctionnalités de Meal Chef AI, vous devez créer un compte. La création de compte n'est disponible que via Google Sign-In ou Apple Sign-In. L'utilisation anonyme de l'Application n'est pas autorisée."
    },
    informationCollection: {
      title: "Informations que Nous Collectons",
      categories: {
        title: "Catégories d'Informations Personnelles",
        intro: "Nous collectons et avons collecté les catégories suivantes d'informations personnelles :",
        authentication: {
          title: "Catégorie A : Informations d'Authentification",
          items: [
            "Adresse e-mail et nom (via Google Sign-In ou Apple Sign-In)",
            "Identifiants de compte",
            "Identifiants d'appareil"
          ]
        },
        userContent: {
          title: "Catégorie B : Contenu Utilisateur",
          items: [
            "Photographies de plats (lorsqu'elles sont sauvegardées dans votre compte)",
            "Recettes sauvegardées",
            "Préférences alimentaires"
          ]
        },
        usage: {
          title: "Catégorie C : Informations d'Utilisation",
          items: [
            "Données d'interaction avec l'application",
            "Suggestions de repas générées par l'IA",
            "Résultats d'analyse des photographies de plats"
          ]
        }
      }
    },
    howWeCollect: {
      title: "Comment Nous Collectons les Informations",
      items: [
        "Authentification via Google Sign-In ou Apple Sign-In (requis)",
        "Accès à l'appareil photo pour la photographie culinaire (avec autorisation)",
        "Autorisations de notification optionnelles",
        "Saisie directe de l'utilisateur (préférences, recettes sauvegardées)"
      ]
    },
    legalBasis: {
      title: "Base Juridique du Traitement (RGPD)",
      items: [
        "Contrat : Pour fournir nos services par abonnement",
        "Consentement : Pour le traitement des photographies de plats et l'envoi de notifications optionnelles",
        "Intérêts légitimes : Pour améliorer nos services et prévenir les abus",
        "Obligation légale : Pour respecter les lois et réglementations applicables"
      ]
    },
    useOfInformation: {
      title: "Utilisation de Vos Informations",
      intro: "Nous utilisons les informations collectées pour :",
      items: [
        "Fournir des suggestions de repas personnalisées utilisant l'IA",
        "Traiter et analyser les photographies de plats",
        "Stocker vos recettes et préférences sauvegardées",
        "Prévenir les abus du système par l'identification des comptes",
        "Maintenir la sécurité et la fonctionnalité de l'application",
        "Envoyer des notifications optionnelles d'expiration d'essai (si activées)",
        "Fournir une assistance client",
        "Maintenir les paramètres de langue appropriés"
      ]
    },
    dataStorage: {
      title: "Stockage des Données et Transferts Internationaux",
      items: [
        "Nous utilisons des fournisseurs de services cloud qui maintiennent des installations dans différents endroits dans le monde",
        "Vos données peuvent être traitées dans des pays en dehors de votre pays de résidence",
        "Pour les transferts depuis l'EEE, nous assurons des garanties appropriées via :",
        [
          "Les Clauses Contractuelles Types (CCT)",
          "Des mesures de protection des données adéquates",
          "Des évaluations régulières de sécurité"
        ],
        "Vous avez le droit de demander des informations sur ces garanties"
      ]
    },
    dataRetention: {
      title: "Conservation et Suppression des Données",
      periods: {
        title: "Nous conservons vos informations pour les périodes suivantes :",
        items: [
          "Informations de compte : Durée de l'activité du compte",
          "Photographies de plats : Jusqu'à la suppression manuelle ou la désactivation du compte",
          "Recettes sauvegardées : Jusqu'à la suppression manuelle ou la désactivation du compte",
          "Données d'utilisation : 12 mois après la collecte",
          "Données de traitement IA : 6 mois après la génération"
        ]
      },
      deactivation: {
        title: "Lors de la désactivation du compte :",
        items: [
          "Toutes les informations de connexion sont effacées",
          "Toutes les photographies sont supprimées",
          "Toutes les recettes sauvegardées sont supprimées",
          "Toutes les préférences sont effacées",
          "Toutes les données d'utilisation sont supprimées"
        ]
      }
    },
    subscription: {
      title: "Abonnement et Paiements",
      items: [
        "Le traitement des paiements est entièrement géré par l'App Store d'Apple",
        "Nous ne collectons ni ne stockons aucune information de paiement",
        "La gestion des abonnements se fait via les paramètres de votre identifiant Apple"
      ]
    },
    appPermissions: {
      title: "Autorisations et Fonctionnalités de l'Application",
      items: [
        "Appareil photo : Requis pour l'analyse des aliments (autorisation requise)",
        "Notifications : Optionnelles pour les rappels d'expiration d'essai uniquement",
        "Langue : Disponible en anglais, français, espagnol, portugais (Portugal), italien et allemand",
        "Disponibilité géographique : Disponible dans tous les territoires où l'App Store d'Apple est accessible"
      ]
    },
    userRights: {
      title: "Vos Droits en Matière de Protection des Données",
      gdpr: {
        title: "Droits RGPD (Utilisateurs de l'UE)",
        intro: "Si vous résidez dans l'Espace Économique Européen (EEE), vous disposez de certains droits en matière de protection des données :",
        items: [
          "Droit d'accès",
          "Droit de rectification",
          "Droit à l'effacement",
          "Droit à la limitation du traitement",
          "Droit à la portabilité des données",
          "Droit d'opposition",
          "Droits relatifs à la prise de décision automatisée"
        ]
      },
      ccpa: {
        title: "Droits CCPA (Résidents de Californie)",
        intro: "Si vous êtes résident de Californie, vous avez le droit de :",
        items: [
          "Demander la divulgation des informations personnelles que nous collectons à votre sujet",
          "Demander la suppression de vos informations personnelles",
          "Refuser la vente d'informations personnelles",
          "Non-discrimination pour l'exercice des droits CCPA"
        ]
      },
      pipeda: {
        title: "Droits PIPEDA (Utilisateurs Canadiens)",
        intro: "En vertu de la PIPEDA, vous avez le droit de :",
        items: [
          "Accéder à vos informations personnelles",
          "Contester l'exactitude de vos informations",
          "Retirer votre consentement",
          "Déposer une plainte concernant nos pratiques de confidentialité"
        ]
      }
    },
    security: {
      title: "Protection et Sécurité des Données",
      items: [
        "Contrôles d'accès et mesures d'authentification",
        "Évaluations régulières de sécurité",
        "Procédures de réponse aux incidents",
        "Mesures de sécurité conformes aux standards de l'industrie"
      ]
    },
    thirdParty: {
      title: "Services Tiers",
      items: [
        "Authentification : Google Sign-In, Apple Sign-In",
        "Services IA : Nous utilisons des services IA tiers pour l'analyse des aliments et les suggestions de repas",
        "Traitement des paiements : Géré par l'App Store d'Apple"
      ]
    },
    userControl: {
      title: "Contrôle des Données Utilisateur",
      intro: "Les utilisateurs peuvent :",
      items: [
        "Modifier les recettes et photographies sauvegardées",
        "Mettre à jour les préférences alimentaires",
        "Contrôler les paramètres de notification",
        "Demander l'exportation des données via le support",
        "Supprimer le compte et les données associées",
        "Changer les préférences de langue"
      ]
    },
    contact: {
      title: "Nous Contacter",
      text: "Pour toute question concernant la confidentialité :\n\nGwinyai Nyatsoka\nAddress: 1312 Waxberry Way, Westgate\nHarare, Zimbabwe\nEmail : gnyatsoka@gmail.com\nDélai de réponse : Sous 2 jours ouvrés"
    },
    changes: {
      title: "Modifications de la Politique de Confidentialité",
      intro: "Nous informerons les utilisateurs de tout changement important via :",
      items: [
        "Notifications dans l'application",
        "Notes de mise à jour de l'App Store"
      ]
    },
    cookies: {
      title: "Politique des Cookies",
      text: "Notre Application n'utilise pas directement de cookies, mais les services tiers peuvent utiliser des technologies similaires pour l'authentification et la fonctionnalité."
    },
    consent: {
      title: "Consentement",
      text: "En créant un compte et en utilisant notre Application, vous consentez à notre Politique de Confidentialité et acceptez ses termes. Si vous n'êtes pas d'accord avec cette politique, veuillez ne pas créer de compte ni utiliser notre Application."
    }
  }
};

export default function PrivacyPolicyText() {
  const [language, setLanguage] = useState('en');

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const currentContent = content[language];

  return (
    <>
      <div className="language-container">
  <label htmlFor="language-select" className="language-label">
    Select Language / Choisir la langue
  </label>
  <select
    id="language-select"
    value={language}
    onChange={handleLanguageChange}
    className="language-select"
  >
    <option value="en">🇺🇸 English</option>
    <option value="fr">🇫🇷 Français</option>
  </select>
  <div className="dropdown-arrow">
    <svg
      width="20"
      height="20"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 9l-7 7-7-7"
      />
    </svg>
  </div>
</div>

      <SectionWrapper>
        <SubHeading>{currentContent.title}</SubHeading>
        <ParagraphSlant>{currentContent.lastUpdated}</ParagraphSlant>

        <SubHeading2>{currentContent.introduction.title}</SubHeading2>
        <Paragraph>{currentContent.introduction.text}</Paragraph>

        <SubHeading2>{currentContent.dataController.title}</SubHeading2>
        <Paragraph>{currentContent.dataController.text}</Paragraph>

        <SubHeading2>{currentContent.accountRequirement.title}</SubHeading2>
        <Paragraph>{currentContent.accountRequirement.text}</Paragraph>

        <SubHeading2>{currentContent.informationCollection.title}</SubHeading2>
        <ParagraphSlant>{currentContent.informationCollection.categories.title}</ParagraphSlant>
        <Paragraph>{currentContent.informationCollection.categories.intro}</Paragraph>

        <ParagraphSlant>{currentContent.informationCollection.categories.authentication.title}</ParagraphSlant>
        <BulletList>
          {currentContent.informationCollection.categories.authentication.items.map((item, index) => (
            <li key={`auth-${index}`}>{item}</li>
          ))}
        </BulletList>

        <ParagraphSlant>{currentContent.informationCollection.categories.userContent.title}</ParagraphSlant>
        <BulletList>
          {currentContent.informationCollection.categories.userContent.items.map((item, index) => (
            <li key={`content-${index}`}>{item}</li>
          ))}
        </BulletList>

        <ParagraphSlant>{currentContent.informationCollection.categories.usage.title}</ParagraphSlant>
        <BulletList>
          {currentContent.informationCollection.categories.usage.items.map((item, index) => (
            <li key={`usage-${index}`}>{item}</li>
          ))}
        </BulletList>

        <SubHeading2>{currentContent.howWeCollect.title}</SubHeading2>
        <BulletList>
          {currentContent.howWeCollect.items.map((item, index) => (
            <li key={`collect-${index}`}>{item}</li>
          ))}
        </BulletList>

        <SubHeading2>{currentContent.legalBasis.title}</SubHeading2>
        <BulletList>
          {currentContent.legalBasis.items.map((item, index) => (
            <li key={`legal-${index}`}>{item}</li>
          ))}
        </BulletList>

        <SubHeading2>{currentContent.useOfInformation.title}</SubHeading2>
        <Paragraph>{currentContent.useOfInformation.intro}</Paragraph>
        <BulletList>
          {currentContent.useOfInformation.items.map((item, index) => (
            <li key={`use-${index}`}>{item}</li>
          ))}
        </BulletList>

        <SubHeading2>{currentContent.dataStorage.title}</SubHeading2>
        <BulletList>
          {currentContent.dataStorage.items.map((item, index) => (
            <li key={`storage-${index}`}>
              {Array.isArray(item) ? (
                <BulletList>
                  {item.map((subItem, subIndex) => (
                    <li key={`storage-sub-${subIndex}`}>{subItem}</li>
                  ))}
                </BulletList>
              ) : (
                item
              )}
            </li>
          ))}
        </BulletList>

        <SubHeading2>{currentContent.dataRetention.title}</SubHeading2>
        <ParagraphSlant>{currentContent.dataRetention.periods.title}</ParagraphSlant>
        <BulletList>
          {currentContent.dataRetention.periods.items.map((item, index) => (
            <li key={`retention-${index}`}>{item}</li>
          ))}
        </BulletList>

        <ParagraphSlant>{currentContent.dataRetention.deactivation.title}</ParagraphSlant>
        <BulletList>
          {currentContent.dataRetention.deactivation.items.map((item, index) => (
            <li key={`deactivation-${index}`}>{item}</li>
          ))}
        </BulletList>

        <SubHeading2>{currentContent.subscription.title}</SubHeading2>
        <BulletList>
          {currentContent.subscription.items.map((item, index) => (
            <li key={`subscription-${index}`}>{item}</li>
          ))}
        </BulletList>

        <SubHeading2>{currentContent.appPermissions.title}</SubHeading2>
        <BulletList>
          {currentContent.appPermissions.items.map((item, index) => (
            <li key={`permissions-${index}`}>{item}</li>
          ))}
        </BulletList>

        <SubHeading2>{currentContent.userRights.title}</SubHeading2>
        
        <ParagraphSlant>{currentContent.userRights.gdpr.title}</ParagraphSlant>
        <Paragraph>{currentContent.userRights.gdpr.intro}</Paragraph>
        <BulletList>
          {currentContent.userRights.gdpr.items.map((item, index) => (
            <li key={`gdpr-${index}`}>{item}</li>
          ))}
        </BulletList>

        <ParagraphSlant>{currentContent.userRights.ccpa.title}</ParagraphSlant>
        <Paragraph>{currentContent.userRights.ccpa.intro}</Paragraph>
        <BulletList>
          {currentContent.userRights.ccpa.items.map((item, index) => (
            <li key={`ccpa-${index}`}>{item}</li>
          ))}
        </BulletList>

        <ParagraphSlant>{currentContent.userRights.pipeda.title}</ParagraphSlant>
        <Paragraph>{currentContent.userRights.pipeda.intro}</Paragraph>
        <BulletList>
          {currentContent.userRights.pipeda.items.map((item, index) => (
            <li key={`pipeda-${index}`}>{item}</li>
          ))}
        </BulletList>

        <SubHeading2>{currentContent.security.title}</SubHeading2>
        <BulletList>
          {currentContent.security.items.map((item, index) => (
            <li key={`security-${index}`}>{item}</li>
          ))}
        </BulletList>

        <SubHeading2>{currentContent.thirdParty.title}</SubHeading2>
        <BulletList>
          {currentContent.thirdParty.items.map((item, index) => (
            <li key={`thirdParty-${index}`}>{item}</li>
          ))}
        </BulletList>

        <SubHeading2>{currentContent.userControl.title}</SubHeading2>
        <Paragraph>{currentContent.userControl.intro}</Paragraph>
        <BulletList>
          {currentContent.userControl.items.map((item, index) => (
            <li key={`control-${index}`}>{item}</li>
          ))}
        </BulletList>

        <SubHeading2>{currentContent.contact.title}</SubHeading2>
        <Paragraph>{currentContent.contact.text}</Paragraph>

        <SubHeading2>{currentContent.changes.title}</SubHeading2>
        <Paragraph>{currentContent.changes.intro}</Paragraph>
        <BulletList>
          {currentContent.changes.items.map((item, index) => (
            <li key={`changes-${index}`}>{item}</li>
          ))}
        </BulletList>

        <SubHeading2>{currentContent.cookies.title}</SubHeading2>
        <Paragraph>{currentContent.cookies.text}</Paragraph>

        <SubHeading2>{currentContent.consent.title}</SubHeading2>
        <ParagraphSlant>{currentContent.consent.text}</ParagraphSlant>
      </SectionWrapper>
    </>
  );
}
